import {
  COMPONENT_EDIT_SCHEDULED_APPOINTMENT_INFO,
  COMPONENT_SCHEDULE_APPOINTMENTS_TOGGLE_MODAL,
} from '../constants';
import types from './constants';
import initialState from './initial.state';

export default function scheduleAppointmentReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_APPOINTMENT_SCHEDULE_LOAD_LOCATIONS_SUCCESS:
      return {
        ...state,
        validationMessages: [],
        locations: action.response.DoctorCompanyLocationList,
        providers: action.response.PersonResourceList,
        providerLocationMappings: action.response.PersonResourceDoctorGroupList,
        appointmentTypeList: action.response.CompanyAppointmentTemplateList,
        appointmentStatusList: action.response.CompanyAppointmentStatusList,
        doctorGroupsPreferenceList: action.response.DoctorGroupsPreferenceList,
      };
    case types.COMPONENTS_APPOINTMENT_SCHEDULE_ERROR_OCCURED:
      return {
        ...state,
        validationMessages: action.error.ValidationMessages,
      };
    case types.COMPONENTS_APPOINTMENT_SCHEDULE_CHANGE_LOCATION:
      return {
        ...state,
        selectedLocation: action.selectedLocation,
        providersForSelectedLocation: action.providersForSelectedLocation,
        selectedGroupPreferences: action.groupPreferenceList,
        currentCalendarView: Object.assign(
          { ...state.currentCalendarView },
          { ...action.currentCalendarView },
        ),
        validationMessages: [],
      };
    case types.COMPONENTS_APPOINTMENT_SCHEDULE_CHANGE_PROVIDER:
      return {
        ...state,
        selectedProviderId: action.selctedProviderId,
        selectedProvider: action.selectedProvider,
        currentCalendarView: Object.assign({ ...state.currentCalendarView }),
        availableSlots: [],
        validationMessages: [],
      };
    case types.COMPONENTS_APPOINTMENT_SCHEDULE_CHANGE_APPOINTMENTTYPE:
      return {
        ...state,
        selectedAppointmentType: action.selectedAppointmentType,
        availableSlots: [],
        currentCalendarView: Object.assign(
          { ...state.currentCalendarView },
          {
            selectedAppointmentTemplate: action.selectedAppointmentTypeDetails,
          },
        ),
        validationMessages: [],
      };

    case types.COMPONENTS_APPOINTMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduleAppointmentStatus: {},
        locations: [],
        providers: [],
        providerLocationMappings: [],
        appointmentTypeList: [],
        appointmentStatusList: [],
        currentCalendarView: {},
        validationMessages: [],
      };

    case types.COMPONENTS_APPOINTMENT_PICKER_RESET_COMPONENT:
      return {
        ...state,
        availableSlots: [],
      };
    case types.COMPONENTS_APPOINTMENT_SCHEDULE_RESET_COMPONENT:
      return {
        ...state,
        scheduleAppointmentStatus: {},
        locations: [],
        providers: [],
        providerLocationMappings: [],
        appointmentTypeList: [],
        appointmentStatusList: [],
        currentCalendarView: {},
        availableSlots: [],
        isSubmitted: false,
        validationMessages: [],
        selectedLocation: {},
        selectedAppointmentType: {},
        selectedProvider: {},
      };
    case types.COMPONENTS_APPOINTMENT_SCHEDULE_APPOINTMENT_REQUEST_SUCCESS:
      return {
        ...state,
        scheduleAppointmentStatus: action.scheduleAppointmentStatus,
        isSubmitted: action.isSubmitted,
        validationMessages: [],
      };
    case types.COMPONENTS_APPOINTMENT_REQUEST_UPDATE_SELECTED_APPOINTMENT_DATE_LIST:
      return {
        ...state,
        requestedDates: action.dateList,
        validationMessages: action.error.ValidationMessages,
        isSubmitted: false,
      };
    case types.COMPONENTS_APPOINTMENT_REQUEST_CLEAR_VALIDATION_MESSAGES:
      return {
        ...state,
        validationMessages: [],
      };
    case types.COMPONENTS_APPOINTMENT_REQUEST_GET_TIME_INTERVALS:
      return {
        ...state,
        appointmentTimeIntervals: action.times,
        validationMessages: [],
      };
    case types.COMPONENT_APPOINTMENTS_PICKER_TOGGLE_MODAL:
      return {
        ...state,
        showAppointmentPickerModal: action.show,
      };
    case types.COMPONENTS_LOAD_AVAILABLE_SLOTS_OF_PROVIDR_FOR_SCHEDULE_SUCCESS:
      return {
        ...state,
        currentCalendarView: Object.assign({ ...state.currentCalendarView }),
        availableSlots: action.availableSlots,
      };
    case types.COMPONENTS_APPOINTMENTS_CALENDAR_NAVIGATION_CLICK:
      return {
        ...state,
        currentCalendarView: Object.assign(
          { ...state.currentCalendarView },
          { ...action.currentCalendarView },
        ),
      };

    case types.COMPONENTS_APPOINTMENTS_SCHEDULE_SEARCH_FORM_SUCCESS:
      return {
        ...state,
        search_available_appointment_form_errors: {},
      };

    case types.COMPONENTS_APPOINTMENTS_SCHEDULE_SEARCH_FORM_ERROR:
      return {
        ...state,
        search_available_appointment_form_errors: { ...action.searchFormErrors },
      };
    case types.COMPONENTS_APPOINTMENT_SEARCH_SUBMIT_FORM:
      return {
        ...state,
        isSubmitted: action.submit,
        validationMessages: [],
      };

    case types.COMPONENTS_APPOINTMENTS_SCHEDULE_REASON:
      return {
        ...state,
        currentCalendar_View_SelectedSlotScheduleReason: action.reason,
      };
    case types.COMPONENTS_LOAD_APPOINTMENT_INFO_SUCCESS:
      return {
        ...state,
        selectedLocation: action.selectedLocation,
        providersForSelectedLocation: action.providersForSelectedLocation,
        selectedProviderId: action.PersonResourceId,
        selectedProvider: action.selectedProvider,
        selectedAppointmentType: action.selectedAppointmentType,
        currentCalendarView: Object.assign(
          { ...state.currentCalendarView },
          { ...action.currentCalendarView },
        ),
        validationMessages: [],
      };
    case types.COMPONENTS_LOAD_PATIENT_CASES_SUCCESS:
      return {
        ...state,
        patientCases: action.patientCases,
      };
    case types.COMPONENTS_PATIENT_CASE_SELECT:
      return {
        ...state,
        selectedCase: action.selectedCase,
      };
    case types.COMPONENTS_APPOINTMENTS_IS_SCHEDULING:
      return {
        ...state,
        isScheduling: action.isScheduling,
      };
    // Reset to work week view between appointment calendar invocations.
    case COMPONENT_EDIT_SCHEDULED_APPOINTMENT_INFO:
    case COMPONENT_SCHEDULE_APPOINTMENTS_TOGGLE_MODAL:
      return {
        ...state,
        currentCalendarView: Object.assign({ ...state.currentCalendarView }, { view: 'work_week' }),
      };
    default:
      return state;
  }
}
