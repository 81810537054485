import { lazy } from 'react';
import { useAppSelector } from '../store/hooks';
import RxntLaunchDarklyProvider, {
  RxntLaunchDarklyCustomContextData,
} from '../utils/launch-darkly/launch-darkly-provider';

const UnauthenticatedApp = lazy(() => import('./unauthenticated-app'));
const AuthenticatedApp = lazy(() => import('./authenticated-app/authenticated-app'));

export const Routes = () => {
  const isLoggedIn = useAppSelector((store) => store.auth.isLoggedIn);
  const customContextData: RxntLaunchDarklyCustomContextData = {};

  return (
    <RxntLaunchDarklyProvider customContextData={customContextData}>
      {isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </RxntLaunchDarklyProvider>
  );
};
