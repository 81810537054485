import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DatadogNavigationTracker = () => {
  const location = useLocation();

  useEffect(() => {
    datadogRum.startView({
      name: location.pathname,
    });
  }, [location.pathname]);

  return null;
};

export default DatadogNavigationTracker;
