import { datadogRum } from '@datadog/browser-rum';

if (process.env.REACT_APP_ENABLE_DATADOG_RUM === 'true') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'phr',
    env: process.env.REACT_APP_LAUNCH_DARKLY_ENVIRONMENT,
    sessionSampleRate: 10,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackViewsManually: true,
  });
}
