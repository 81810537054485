const COMPONENTS_APPOINTMENT_SCHEDULE_LOAD_LOCATIONS_SUCCESS =
  'COMPONENTS_APPOINTMENT_REQUEST_LOAD_LOCATIONS_SUCCESS';
const COMPONENTS_APPOINTMENT_SCHEDULE_ERROR_OCCURED =
  'COMPONENTS_APPOINTMENT_SCHEDULE_ERROR_OCCURED';

const COMPONENTS_APPOINTMENT_SCHEDULE_CHANGE_LOCATION =
  'COMPONENTS_APPOINTMENT_SCHEDULE_CHANGE_LOCATION';
const COMPONENTS_APPOINTMENT_REQUEST_SUBMIT_FORM = 'COMPONENTS_APPOINTMENT_REQUEST_SUBMIT_FORM';
const COMPONENTS_APPOINTMENT_SCHEDULE_RESET_COMPONENT =
  'COMPONENTS_APPOINTMENT_SCHEDULE_RESET_COMPONENT';
const COMPONENTS_APPOINTMENT_SCHEDULE_APPOINTMENT_REQUEST_SUCCESS =
  'COMPONENTS_APPOINTMENT_SCHEDULE_APPOINTMENT_REQUEST_SUCCESS';
const COMPONENTS_APPOINTMENT_REQUEST_UPDATE_SELECTED_APPOINTMENT_DATE_LIST =
  'COMPONENTS_APPOINTMENT_REQUEST_UPDATE_SELECTED_APPOINTMENT_DATE_LIST';
const COMPONENTS_APPOINTMENT_REQUEST_CLEAR_VALIDATION_MESSAGES =
  'COMPONENTS_APPOINTMENT_REQUEST_CLEAR_VALIDATION_MESSAGES';
const COMPONENTS_APPOINTMENT_REQUEST_GET_TIME_INTERVALS =
  'COMPONENTS_APPOINTMENT_REQUEST_GET_TIME_INTERVALS';
const COMPONENT_APPOINTMENTS_PICKER_TOGGLE_MODAL = 'COMPONENT_APPOINTMENTS_PICKER_TOGGLE_MODAL';
const COMPONENTS_APPOINTMENT_SCHEDULE_CHANGE_PROVIDER =
  'COMPONENTS_APPOINTMENT_SCHEDULE_CHANGE_PROVIDER';
const COMPONENTS_APPOINTMENT_SCHEDULE_CHANGE_APPOINTMENTTYPE =
  'COMPONENTS_APPOINTMENT_SCHEDULE_CHANGE_APPOINTMENTTYPE';
const COMPONENTS_LOAD_AVAILABLE_SLOTS_OF_PROVIDR_FOR_SCHEDULE_SUCCESS =
  'COMPONENTS_LOAD_AVAILABLE_SLOTS_OF_PROVIDR_FOR_SCHEDULE_SUCCESS';
const COMPONENTS_LOAD_AVAILABLE_SLOTS_OF_PROVIDR_FOR_SCHEDULE_ERROR =
  'COMPONENTS_LOAD_AVAILABLE_SLOTS_OF_PROVIDR_FOR_SCHEDULE_ERROR';
const COMPONENTS_APPOINTMENTS_CALENDAR_NAVIGATION_CLICK =
  'COMPONENTS_APPOINTMENTS_CALENDAR_NAVIGATION_CLICK';

const COMPONENTS_APPOINTMENTS_SCHEDULE_SEARCH_FORM_SUCCESS =
  'COMPONENTS_APPOINTMENTS_SCHEDULE_SEARCH_FORM_SUCCESS';
const COMPONENTS_APPOINTMENTS_SCHEDULE_SEARCH_FORM_ERROR =
  'COMPONENTS_APPOINTMENTS_SCHEDULE_SEARCH_FORM_ERROR';
const COMPONENTS_APPOINTMENT_SEARCH_SUBMIT_FORM = 'COMPONENTS_APPOINTMENT_SEARCH_SUBMIT_FORM';
const COMPONENTS_APPOINTMENTS_SCHEDULE_REASON = 'COMPONENTS_APPOINTMENTS_SCHEDULE_REASON';
const COMPONENTS_APPOINTMENT_SCHEDULE_SUCCESS = 'COMPONENTS_APPOINTMENT_SCHEDULE_SUCCESS';
const COMPONENTS_LOAD_APPOINTMENT_INFO_SUCCESS = 'COMPONENTS_LOAD_APPOINTMENT_INFO_SUCCESS';
const COMPONENTS_LOAD_APPOINTMENT_INFO_ERROR_OCCURED =
  'COMPONENTS_LOAD_APPOINTMENT_INFO_ERROR_OCCURED';
const COMPONENTS_APPOINTMENT_SCHEDULE_GET_GROUP_PREFERENCE_ERROR =
  'COMPONENTS_APPOINTMENT_SCHEDULE_GET_GROUP_PREFERENCE_ERROR';

const COMPONENTS_LOAD_PATIENT_CASES_SUCCESS = 'COMPONENTS_LOAD_PATIENT_CASES_SUCCESS';
const COMPONENTS_LOAD_PATIENT_CASES_ERROR = 'COMPONENTS_LOAD_PATIENT_CASES_ERROR';
const COMPONENTS_PATIENT_CASE_SELECT = 'COMPONENTS_PATIENT_CASE_SELECT';
const COMPONENTS_APPOINTMENTS_IS_SCHEDULING = 'COMPONENTS_APPOINTMENTS_IS_SCHEDULING';

export const COMPONENTS_HOME_MASTER_START_SPINNER = 'COMPONENTS_HOME_MASTER_START_SPINNER';
export const COMPONENTS_HOME_MASTER_STOP_SPINNER = 'COMPONENTS_HOME_MASTER_STOP_SPINNER';
export const COMPONENTS_APPOINTMENT_PICKER_RESET_COMPONENT =
  'COMPONENTS_APPOINTMENT_PICKER_RESET_COMPONENT';

export const APPOINTMENT_SELF_SCHEDULE_CHARGE_CODE = 'DRASS';

export default {
  COMPONENTS_APPOINTMENT_SCHEDULE_LOAD_LOCATIONS_SUCCESS,
  COMPONENTS_APPOINTMENT_SCHEDULE_ERROR_OCCURED,
  COMPONENTS_APPOINTMENT_SCHEDULE_CHANGE_LOCATION,
  COMPONENTS_APPOINTMENT_REQUEST_SUBMIT_FORM,
  COMPONENTS_APPOINTMENT_SCHEDULE_RESET_COMPONENT,
  COMPONENTS_APPOINTMENT_SCHEDULE_APPOINTMENT_REQUEST_SUCCESS,
  COMPONENTS_APPOINTMENT_PICKER_RESET_COMPONENT,
  COMPONENTS_APPOINTMENT_REQUEST_UPDATE_SELECTED_APPOINTMENT_DATE_LIST,
  COMPONENTS_APPOINTMENT_REQUEST_CLEAR_VALIDATION_MESSAGES,
  COMPONENTS_APPOINTMENT_REQUEST_GET_TIME_INTERVALS,
  COMPONENT_APPOINTMENTS_PICKER_TOGGLE_MODAL,
  COMPONENTS_APPOINTMENT_SCHEDULE_CHANGE_PROVIDER,
  COMPONENTS_APPOINTMENT_SCHEDULE_CHANGE_APPOINTMENTTYPE,
  COMPONENTS_LOAD_AVAILABLE_SLOTS_OF_PROVIDR_FOR_SCHEDULE_SUCCESS,
  COMPONENTS_LOAD_AVAILABLE_SLOTS_OF_PROVIDR_FOR_SCHEDULE_ERROR,
  COMPONENTS_APPOINTMENTS_CALENDAR_NAVIGATION_CLICK,
  COMPONENTS_APPOINTMENTS_SCHEDULE_SEARCH_FORM_SUCCESS,
  COMPONENTS_APPOINTMENTS_SCHEDULE_SEARCH_FORM_ERROR,
  COMPONENTS_APPOINTMENT_SEARCH_SUBMIT_FORM,
  COMPONENTS_APPOINTMENTS_SCHEDULE_REASON,
  COMPONENTS_APPOINTMENT_SCHEDULE_SUCCESS,
  COMPONENTS_LOAD_APPOINTMENT_INFO_SUCCESS,
  COMPONENTS_LOAD_APPOINTMENT_INFO_ERROR_OCCURED,
  COMPONENTS_APPOINTMENT_SCHEDULE_GET_GROUP_PREFERENCE_ERROR,
  COMPONENTS_LOAD_PATIENT_CASES_SUCCESS,
  COMPONENTS_LOAD_PATIENT_CASES_ERROR,
  COMPONENTS_PATIENT_CASE_SELECT,
  COMPONENTS_APPOINTMENTS_IS_SCHEDULING,
};
