import { IosShare } from '@mui/icons-material';

export const appConstants = {
  phrV2WebApiRoutes: {
    getWeavyPatientConfig: '/weavy/GetWeavyPatientConfig',
    getWeavyUserToken: '/weavy/GetWeavyUserToken',
    getCurrentClientVersion: '/utilities/GetCurrentClientVersion/',
    authenticateUserV2: 'authentication/AuthenticateUserV2',
    logOutRoute: '/authentication/Logout',
    authenticateUserByToken: '/authentication/AuthenticateUserByToken',
    loadDoctorsRoute: '/messages/LoadDoctors',
    searchOutboxMessagesRoute: '/messages/SearchOutboxMessages',
    searchInboxMessagesRoute: '/messages/SearchInboxMessages',
    deleteDoctorPatientMessagesRoute: '/messages/DeleteDoctorPatientMessages',
    updatePatientCompletedMessageRoute: '/messages/UpdatePatientCompletedMessage',
    downloadCCDProfileRoute: '/patient/DownloadCCDProfile',
    transmitCCDProfileRoute: '/patient/TransmitCCDProfile',
    getDoctorCompanyModuleAccessRoute: '/company/GetDoctorCompanyModuleAccess',
    getDocumentFromDocSrvcRoute: 'patientdocuments/GetDocumentFromDocSrvc',
    getAllPatientRepresentatives: 'patient/representative/GetAllPatientRepresentatives',
    getPatientRepresentativeById: 'patient/representative/GetPatientRepresentativeById',
    getRepresentativeCellNumber: 'patient/representative/GetPatientRepresentativeCellNumber',
    SavePatientRepresentative: 'patient/representative/SavePatientRepresentative',
    DeletePatientRepresentative: 'patient/representative/DeletePatientRepresentative',
    UpdatePatientRepresentativePassword:
      'patient/representative/UpdatePatientRepresentativePassword',
    loadStatesRoute: 'patient/representative/GetStates',
    loadZipCodeCitiesRoute: 'patient/representative/GetZipCodeCities',
    loadRepMaritalStatusesRoute: 'patient/representative/GetMaritalStatuses',
    loadRepPhonePreferenceTypesRoute: 'patient/representative/GetPhonePreferenceTypes',
    loadPersonRelationshipsRoute: 'patient/representative/GetPersonRelationships',
    searchActivitiesRoute: 'activity/SearchActivitiesV3',
    getAllDocuments: 'patientdocuments/GetAllPatientPortalDocuments',
    SaveDocument: 'patientdocuments/SavePatientPortalDocuments',
    DeleteDocument: 'patientdocuments/DeletePatientPortalDocument',
    SearchPatientPastHxMedication: 'patientPastHxMedication/SearchPatientPastHxMedication',
    SearchMedication: 'patientPastHxMedication/SearchMedications',
    GetAllDrugUnits: 'patientPastHxMedication/GetAllDrugUnits',
    SaveMedication: 'patientPastHxMedication/SavePatientPastHxMedication',
    DeletePatientPastHxMedication: 'patientPastHxMedication/DeletePatientPastHxMedication',
    searchFriendlyDiagnosis: 'patientPastHxMedication/SearchPortalFriendlyDiagnosis',
    getAllergiesData: '/patientPastHxAllergy/SearchPatientPastHxAllergies',
    searchAllergies: '/patientPastHxAllergy/searchAllergies',
    deletePatientPastHxAllergies: '/patientPastHxAllergy/DeletePatientPastHxAllergy',
    savePatientPastHxAllergies: '/patientPastHxAllergy/SavePatientPastHxAllergies',
    getMedicalHxData: '/patientMedicalHistory/SearchPatientMedicalHx',
    saveMedicalHx: '/patientMedicalHistory/SavePatientMedicalHx',
    deleteMedicalHx: '/patientMedicalHistory/DeletePatientMedicalHx',
    getSurgicalHxData: '/patientSurgicalHistory/SearchPatientSurgicalHx',
    saveSurgicalHx: '/patientSurgicalHistory/SavePatientSurgicalHx',
    deleteSurgicalHx: '/patientSurgicalHistory/DeletePatientSurgicalHx',
    getHospitalizationHxData: '/patientHospitalization/SearchPatientHospitalization',
    saveHospitalizationHx: '/patientHospitalization/SavePatientHospitalization',
    deleteHospitalizationHx: '/patientHospitalization/DeletePatientHospitalization',
    getImplantableDeviceHistory: '/patientPastHxImplantableDevice/SearchPatientImplantableDevice',
    searchImplantableDevice: '/patientPastHxImplantableDevice/SearchImplantableDevice',
    saveImplantableDevice: '/patientPastHxImplantableDevice/SavePatientImplantableDevice',
    deleteImplantableDevice: '/patientPastHxImplantableDevice/DeletePatientImplantableDevice',
    GetLabs: '/patientlabresults/GetLabs',
    GetLabResult: '/patientlabresults/GetLabResult',
    GetPatientFile: '/patient/GetPatientFile',
    SearchPatientFamilyHx: '/patientFamilyHistory/SearchPatientFamilyHx',
    SavePatientFamilyHx: '/patientFamilyHistory/SavePatientFamilyHx',
    DeletePatientFamilyHx: '/patientFamilyHistory/DeletePatientFamilyHx',
    SearchPatientFamHxConstants: '/patientFamilyHistory/SearchPatientFamHxConstants',
    GetPatientSocialHx: '/patientSocialHistory/GetPatientSocialHx',
    SavePatientSocialHx: '/patientSocialHistory/SavePatientSocialHx',
    DeletePatientSocialHxSmokingStatus: '/patientSocialHistory/DeletePatientSocialHxSmokingStatus',
    GetPatientSocialHxConstants: '/patientSocialHistory/GetPatientSocialHxConstants',
    getApplicationConfig: '/utilities/GetApplicationConfig/',
    sendEmail: '/utilities/sendEmail/',
    getLocationsAndPersonResourcesForCompanyRoute:
      '/patientappointments/GetLocationsAndPersonResourcesForCompany/',
    searchPatientAppointmentsRoute: '/patientappointments/SearchPatientAppointments',
    getPendingAppointmentsCountRoute: '/patientappointments/GetPendingAppointmentsCount',
    blockAppointmentCalendarRoute: '/patientappointments/blockAppointmentCalendar',
    checkInAppointmentRoute: '/patientappointments/CheckInAppointment',
    verifyCheckInRequirementsAreCompleteRoute:
      '/patientappointments/VerifyCheckInRequirementsAreComplete',
    GetCompanyApplicationConfigurationRoute: 'utilities/GetCompanyApplicationConfiguration/',
    confirmPatientEmailForPortalRegistrationRoute:
      '/authentication/ConfirmPatientEmailForPortalRegistration',
    validateAndSendMailForResetPasswordRoute: '/authentication/ValidateAndSendMailForResetPassword',
    setNewPasswordRoute: '/authentication/SetNewPassword',
    forgotUserNameRoute: '/authentication/ForgotUserName',
    getPatientInfoByEmailTokenRoute: '/authentication/GetPatientInfoByEmailToken',
    getPatientDemographicsRoute: 'patient/GetPatientDemographics',
    getSexualOrientationsRoute: 'person/GetSexualOrientations',
    getGenderIdentitiesRoute: 'person/GetGenderIdentities',
    getRaceCategoriesRoute: 'person/GetRaceCategories',
    getEthnicityCategoriesRoute: 'person/GetEthnicityCategories',
    getEmploymentStatusesRoute: 'person/GetEmploymentStatuses',
    getCommunicationPreferenceTypesRoute: 'person/GetCommunicationPreferenceTypes',
    getMaritalStatusesRoute: 'person/GetMaritalStatuses',
    getDetailedRacesRoute: 'person/GetDetailedRaces',
    getPreferredLanguages: 'person/GetPreferredLanguages',
    getPatientDetailedRacesRoute: 'patient/GetPatientDetailedRaces',
    getPreferredPhoneTypesRoute: 'person/GetPreferredPhoneTypes',
    getPreferredLanguagesRoute: 'person/GetPreferredLanguages',
    SearchPatientPayments: '/billing/SearchPatientPayments',
    GenerateBillingStatements: '/billing/GenerateStatement',
    getPrintStatementMessage: '/billing/GetPrintStatementMessage',
    GetAllStatementsByPatient: '/billing/GetAllStatementsByPatient',
    getDoctorsAvailableAppointments: '/patientscheduler/GetDoctorAvailableAppointments/',
    getCompanyAccessPreferenceMasterRoute: '/company/GetDoctorCompanyAccessPreferenceList',
    getCompanyGroupTimeZonesMasterRoute: '/company/GetSchedulerPreferredTimeZonesForAllGroups',
    ScheduleAppointmentRequestRoute: 'patientappointments/ScheduleAppointment',
    GetAppointmentInfoForMail: 'patientscheduler/GetAppointmentInfoForMail',
    getAppointmentInfoRoute: 'patientscheduler/GetAppointment',
    getAllGroupPreferenceRoute: 'patientappointments/GetAllGroupPreference',
    getAppilcationStatusListRoute: '/patientappointments/GetAppointmentStatusTypes/',
    updateAppointmentStatusRoute: '/patientscheduler/UpdateAppointmentStatus/',
    getDoctorCompanyContactDeatilsRoute: '/company/GetDoctorCompanyContactDeatils',
    GetDoctorDetailsRoute: '/doctor/GetDoctorDetails',
    GetPatientProfileImageRoute: '/patient/GetPatientProfileImage',
    GetUpcomingAppointmentsRoute: 'patientappointments/GetUpcomingAppointments',
    GetOutstandingBalanceRoute: '/billing/GetOutstandingBalance',
    GetCurrentMedicationsRoute: '/medications/GetCurrentMedications',
    GetUnreadMessagesCountRoute: '/messages/GetUnreadMessagesCount',
    AcceptTermsAndConditions: '/authentication/AcceptTermsAndConditions',
    GetNotificationPreferencesRoute: '/settings/GetNotificationPreferences',
    SaveNotificationPreferencesRoute: '/settings/SaveNotificationPreferences',
    GetPatientEncounters: '/patientdocuments/GetPatientEncountersV2',
    GetEncounterPdfById: '/patientdocuments/GetEncounterPdfById',
    GetAllPatientDocumentsV2: '/patientdocuments/GetAllPatientDocumentsV2',
    searchAllergyReactions: '/patientPastHxAllergy/SearchAllergyReactions',
    registrationDetailsRoute: 'registration/',
    registrationValidatePatientRoute: 'registration/ValidatePatient',
    registrationRequestNewOtpRoute: 'registration/RequestNewOtp',
    registrationValidateOtpRoute: 'registration/ValidateOtp',
    registrationCreateAccountRoute: 'registration/CreateAccount',
    registrationGetPersonRelationshipsRoute: 'registration/GetPersonRelationships',
    getAllPatientCasesRoute: '/patient/GetAllPatientCases',
    getCompanyAppointmentTemplateRoute: '/patientscheduler/GetCompanyAppointmentTemplate',
    updateAppointmentCaseId: '/patientappointments/UpdateCaseForAppointment',
  },
  apiRoutes: {
    getHomeDataRoute: '/phrNodeApiInterface/GetPatientDetails',
    getPatientDetailsRoute: '/phrNodeApiInterface/GetPatientDetails',
    getProblemsDataRoute: '/phrNodeApiInterface/GetPatientProblems',
    getVitalsDataRoute: '/phrNodeApiInterface/GetPatientVitals',
    getProceduresDataRoute: '/phrNodeApiInterface/GetPatientProcedures',
    createAppointmentRequestRoute: '/phrNodeApiInterface/CreateAppointmentRequest',
    deleteAppointmentRequestRoute: '/phrNodeApiInterface/DeleteAppointmentRequest',
    getAllAppointmentRequestsRoute: '/phrNodeApiInterface/GetAllAppointmentRequests',
    getAppointmentGeneralDaysRoute: '/phrNodeApiInterface/GetAppointmentGeneralDays',
    getAppointmentGeneralTimesRoute: '/phrNodeApiInterface/GetAppointmentGeneralTimes',
    getAppointmentNotificationCountRoute: '/phrNodeApiInterface/GetAppointmentNotificationCount',
    createDemographicsUpdateRequestRoute: '/phrNodeApiInterface/CreateDemographicsUpdateRequest',
    loadPatientMessagesRoute: '/phrNodeApiInterface/LoadPatientMessages',
    deletePatientMessagesRoute: '/phrNodeApiInterface/DeletePatientMessages',
    getConsentFormsDataRoute: '/phrNodeApiInterface/GetPatientConsentForms',
    getConsentFormDataRoute: '/phrNodeApiInterface/getPatientConsentForm',
    signConsentFormDataRoute: '/phrNodeApiInterface/SignPatientConsentForm',
    getDoctorCompanyLogoRoute: '/phrNodeApiInterface/GetDoctorCompanyLogo',
    getNewConsentFormsCountRoute: '/phrNodeApiInterface/GetPatientNewConsentFormsCount',
    getNewIntakeFormsCountRoute:
      'patientIntakeFormsNodeApiInterface/GetCountForIntakeFormEncountersForPatient',
    generateBillingStatementRoute: 'phrNodeApiInterface/GenerateBillingStatement',
    bulkMarkMessagesAsReadRoute: 'phrNodeApiInterface/BulkMarkMessagesAsRead',
  },
  browserRoutes: {
    patientEnrollment: '/patient-enrollment',
  },
  messages: {
    validationMessage: 'Please correct the errors',
    exceptionMessage: 'Exception occurred. Please contact the administrator.',
    registrationLinkExpiredMessage:
      'This PIN has expired. Please click the option to request a new PIN and complete the steps.',
  },
  notificationType: {
    NewIntakeForm: 1,
    ConsentForm: 2,
    AppointmentScheduled: 3,
    DemographicsUpdated: 4,
    UpcomingAppointment: 5,
    NewProviderMessage: 6,
    NewLabResult: 7,
    TextThisCoupon: 9,
    VirtualAppointmentScheduled: 10,
  },
  toastr: {
    timeOut: 5000,
  },
  appInfo: {
    applicationName: 'RxNTSeedApp',
  },
  messageStatus: {
    tentative: 'TNTVE',
    accepted: 'ACPTD',
    rejected: 'RJCTD',
    pending: 'PENDG',
  },
  messageAction: {
    read: { code: 'READ', name: 'Read' },
  },
  actionPerformedBy: {
    patient: 'PATNT',
    provider: 'PRVDR',
  },
  validationStatus: {
    success: 'Success',
    failed: 'Failed',
  },
  appointmentStatus: {
    cancelled: 'CND',
  },
  appointmentSessions: [
    { code: 'MRNG', name: 'Morning' },
    { code: 'NOON', name: 'Afternoon' },
  ],
  DataReload: {
    TimeOut: 300000,
  },
  patientMailTypes: {
    patientPortalRegistration: 'PPREG',
    patientEmailConfirmation: 'EMCNF',
    patientResetPassword: 'RSPWD',
  },
  messageTypes: {
    clinicalMessage: 'CLNCL',
    demographicRequest: 'DEMRQ',
    appointmentRequest: 'APPRQ',
  },
  companyAccessPreferenceCheck: [
    { preferenceCode: 'ALPSA', valueType: 'CPONF', makeTrue: 'PRFON' },
    { preferenceCode: 'EPOAU', valueType: 'CPONF', makeTrue: 'PRFON' },
  ],
  externalUrls: {
    clinicalDataApiUrl: 'https://help.rxnt.com/hc/en-us/articles/4415806336279-RXNT-Patient-API',
    optOut: 'https://www.rxnt.com/opt-out/',
    clientServicesPrivacyPolicy: 'https://www.rxnt.com/client-services-privacy-policy/',
    troubleLoggingIn:
      'https://help.rxnt.com/hc/en-us/articles/25072024984215-What-do-I-do-if-I-can-t-login#h_01J3D9R2D8S74JE3D96FPZK8B4',
  },
  dashletError: 'Unexpected error ocurred. Please refresh the page to try again.',
  messageStatuses: [
    { key: 1, value: 1, text: 'All Messages' },
    { key: 2, value: 2, text: 'Complete' },
    { key: 3, value: 3, text: 'Incomplete' },
  ],
  termsAndConditions: (
    <>
      I have read and I accept the MyRXNT{' '}
      <a
        href="https://www.rxnt.com/myrxnt-terms-conditions/"
        target="_blank"
        rel="noreferrer"
        style={{ color: '#00629B', textDecoration: 'underline' }}
      >
        Terms and Conditions
      </a>{' '}
      and{' '}
      <a
        href="https://www.rxnt.com/myrxnt-privacy-policy/"
        target="_blank"
        rel="noreferrer"
        style={{ color: '#00629B', textDecoration: 'underline' }}
      >
        Privacy Policy
      </a>
    </>
  ),
  sexOptions: [
    { value: 'M', text: 'Male' },
    { value: 'F', text: 'Female' },
    { value: 'U', text: 'Unspecified' },
  ],
  communicationPreferences: [
    { value: 'text', text: 'Text' },
    { value: 'email', text: 'Email' },
  ],
  sanitizeRegex: {
    digits: new RegExp(/[^0-9]/g),
    zipCode: new RegExp(/[^0-9-]/g), // numeric characters & hyphen
  },
} as const;

export default appConstants;
