import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { RxntTheme } from '@rxnt/mui-themes';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { HashRouter } from 'react-router-dom';

import appConstants from '../app.constants';
import AppProvider from '../contexts/app-provider.context';
import DatadogNavigationTracker from '../DatadogNavigationTracker';
import store from '../store/store';
import { Spinner } from '../uicomponents/spinner/spinner';
import RouterErrorBoundary from './router-error-boundary';
import { Routes } from './routes';

const App = () => (
  <React.StrictMode>
    <Provider store={store}>
      <AppProvider>
        <ReduxToastr
          timeOut={appConstants.toastr.timeOut}
          newestOnTop={true}
          preventDuplicates={true}
          position="bottom-left"
        />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={RxntTheme}>
            <CssBaseline />
            <Suspense fallback={<Spinner size="lg" fullScreen openBackdrop />}>
              <RouterErrorBoundary>
                <HashRouter>
                  <DatadogNavigationTracker />
                  <Routes />
                </HashRouter>
              </RouterErrorBoundary>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </AppProvider>
    </Provider>
  </React.StrictMode>
);

export default App;
